import { AiFillHome } from "react-icons/ai";
import { BsGridFill } from "react-icons/bs";
import { FiBox } from "react-icons/fi";

export const menuData = [
  {
    title:"Home",
    path:"/home",
    icon:<AiFillHome/>,
    scrollId:""
  },
  {
    title:"Gamebase",
    path:"/gamebase",
    icon:<BsGridFill/>,
    scrollId:""
  },
  {
    title:"INO",
    path:"/ino",
    icon:<FiBox/>,
    scrollId:""
  },
]

export const jockeyClub = {
  img :"https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2Flogo.jpg?alt=media&token=b7458a58-a9ee-409e-93e7-f00101eeb84c",
  game:"jockeyclub",
  name:'Jockey Club',
  desc:"gamebase",
  link:'https://jockeyclub.online/',
  price:'200',
  unit:'FIST/BOX',
  banner:[
      {
          img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F1.png?alt=media&token=754b82bc-f64a-448b-98e0-09b581207575",
      },
      {
          img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F2.png?alt=media&token=f7c54f32-8b20-4a30-b992-9d65351a15fe",
      },
      {
          img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F3.png?alt=media&token=28dff649-79cf-445b-a815-30a50c96e5e0",
      },
      {
          img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F4.png?alt=media&token=7b9cf208-9c68-4593-94be-5813dbd85a26",
      },
      {
          img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F5.png?alt=media&token=892f1961-aac1-4d9e-90d4-c3bb68837f42",
      },
  ]
}

export const pamperDog = {
  img :require('../assets/gamebase/pamperDog/post.png'),
  game:"pamperdog",
  name:'Pamper Dog',
  desc:"PamperDog 是一款建构于BSC 区块链上开发的运动养成类型休闲 GameFi 链游，区块链技术永久保存宠物在链上陪伴着主人，透过丰富的PVE机遇事件，轻松休闲的PVP互动，多渠道的繁育管道，用户利用零碎的休闲时间也能获得明确长期稳定的投资回报以及游戏乐趣。",
  link:'https://asg.games/?utm_source=tokenpocket#/',
  price:'',
  unit:'',
  banner:[
    {
      img: require('../assets/gamebase/pamperDog/banner1.png'),
    },
    {
      img: require('../assets/gamebase/pamperDog/banner2.png'),
    },
  ]
}

export const heroOfMech = {
  img :require('../assets/gamebase/heroOfMech/post.jpg'),
  game:"heroofmech",
  name:'Hero Of Mech',
  desc:"机甲英雄是款BSC链上的3D回合制卡牌战争游戏，玩家宛若身历其境在元宇宙中，运用不同属性的卡牌以及有限资源，组建自己的机甲舰队，每个机甲拥有6种基础属性以及攻防技能，在思考装备如何配置的同时结合每回合不同的策略击败对手。",
  link:'https://asg.games/?utm_source=tokenpocket#/',
  price:'',
  unit:'',
  banner:[
  {
      img: require('../assets/gamebase/heroOfMech/banner1.jpg'),
  },
  {
      img: require('../assets/gamebase/heroOfMech/banner2.jpg'),
  },
  {
      img: require('../assets/gamebase/heroOfMech/banner3.jpg'),
  },
  {
      img: require('../assets/gamebase/heroOfMech/banner4.jpg'),
  },
  {
      img: require('../assets/gamebase/heroOfMech/banner5.jpg'),
  },
]
}


export const brandLink = [
    {
      img:require('../assets/brand/twitter.png'),
      link:'https://twitter.com/Fonfunofficial'
    },

      {
        img:require('../assets/brand/tg.png'),
        link:'https://t.me/fonfunofficial'
      },


]





import { brandLink } from "../global/constants"
import { useTranslation } from "react-i18next";
const Footer = () =>{
  const { t } = useTranslation();

    return <div className="bg-main bg-opacity-0 relative border-solid border-t border-white">
        <div className=" justify-between items-end appWidth  pt-[30px] mb-[45px]">
            <div>
      <p className="hidden text-[28px] font-bold specFont opacity-80 mb-2">Don't miss out, Stay updated</p>
   
      <div  className="hidden mb-8 xl:mb-0 flex justify-between max-w-[464px] w-full bg-white bg-opacity-10 rounded-xl p-1">
      <input className="px-3 sm:px-6 w-full opacity-60 bg-transparent" type="email" placeholder="Enter your email"/>

      <button className="relative blingBtn p-4 specFont text-sm sm:text-xl text-white">SUBSCRIBE</button>
      </div>
      </div>
      <div className='w-full'>
      <p className="hidden text-[15px] opacity-80 mb-4"  >Don't hesitate to subscribe for the latest news and key news about FonFun community</p>
      <div className="w-full lg:flex justify-between">
      <div className='flex'>
      {
        brandLink.map((i,index) => <a href={i.link} target="_blank"  key={index} className='w-10 h-10 cursor-pointer bg-main3 bg-opacity-70 p-[10px] flex rounded-lg mr-3'>
            <img className=' m-auto' src={i.img}  alt=""/>
        </a>)
      }
     </div>
     <div>
     <p className="mt-4 lg:mt-0 text-center lg:text-right text-[14px] specFont   text-main3">{t("footer.message1")}</p>
     <p className="mt-6 lg:mt-0 text-center lg:text-right text-[14px] specFont   text-main3">© 2022. All rights reserved by FonFun</p>
     </div>
      </div>
      </div>
      

     
      
      </div>
    
    </div>
}
export default Footer
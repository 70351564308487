
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { jockeyClub ,pamperDog,heroOfMech} from "../../global/constants";

const Gamebase = () => {
    const { t } = useTranslation();
    const gameList = [
        pamperDog,
        heroOfMech
    ]
   

  

    useEffect(() => {
     
    }, [])


    return <div className="appWidth ">


        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-40">
            {
                gameList.map(i=>
                    <Link to={`/gamedetail?name=${i.game}`} className="relative  bg-main2 bg-opacity-90 rounded-md p-4">
        <img className=" absolute right-0 top-0 w-[25%] object-contain " src={require("../../assets/gamecorner.png")} />

            <div className="flex lg:mr-5 w-full  rounded-md  justify-center py-2 mb-4 lg:mb-0">
                <div className="w-60 float-none  mx-auto">
                    <div className='ratio-1/1 '>
                        <div className='ratio-in '>
                            <img className="w-full h-full object-cover rounded-xl" src={i.img} ></img>
                        </div>
                    </div>
                </div>
            </div>

            <p className="font-bold  text-sm text-white ">{t(i.name)}</p>

        </Link>
                    
                    
                    )
            }
        
        </div>


        <img className="mx-auto absolute right-[0%] bottom-[-60px]  lg:-bottom-40 w-20 h-20 lg:w-40 lg:h-40 object-contain " src={require("../../assets/3.png")} />

    </div>
}

export default Gamebase
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1MMc9lRBXnj1AFq3PxQV1RQfp0PssOq0",
  authDomain: "fonfun-e631c.firebaseapp.com",
  projectId: "fonfun-e631c",
  storageBucket: "fonfun-e631c.appspot.com",
  messagingSenderId: "846406190160",
  appId: "1:846406190160:web:f94cd485c20ae280307d55",
  measurementId: "G-FLW5Y22PNK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
import { brandLink } from "../../global/constants"


import { useEffect, useState } from "react"

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
const MyWallet = () => {
    const { t } = useTranslation();

    const contract_address = "0x8B4cd8f2aac050056049a8D965e927Db2267bbac"

    const abi_json = require("../../assets/contract/jockeyclubbox.json");

    const [stage0, setStage0] = useState([])

    async function getJockeyClubNFT() {


        var Web3 = require("web3");
        console.log(Web3.givenProvider);

        let binancemainnet =
        "https://bsc-dataseed.binance.org/";
        var web3 = new Web3(Web3.givenProvider);
        //web3.setProvider(binancemainnet);

        const chainId = await web3.eth.getChainId();

        if (chainId != 56) {  // 56 main   97 test
            toast.error(t("toast.switch bsc mainnet"));
            return;
        }

        web3.eth.getAccounts().then(async (data) => {
            console.log(data);
            if (data.length == 0) {
                return;
            }

            var YourContract = new web3.eth.Contract(
                abi_json,
                contract_address
            );
            

            let getStage0 = await YourContract.methods.tokensByOwner(data[0]).call()
            console.log(getStage0)
            setStage0(getStage0)


        });
    }

    useEffect(() => {
        getJockeyClubNFT()
    }, [])

    return <div className="appWidth min-h-screen">
        <ToastContainer position="top-center" />
        <p className="font-bold mb-8">{t('My NFT')}</p>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-5" >
            {
                stage0.map((i,index)=> 
                    <div key={index} className="lg:mr-5 w-full  bg-main bg-opacity-40 rounded-md  justify-center p-4 mb-10 cursor-pointer">
                        <a href="https://jockeyclub.online/" target="_blank">
                            <div className="w-full mx-auto">
                                <div className='ratio-1/1 '>
                                    <div className='ratio-in '>
                                        <img className="w-full h-full object-contain rounded-xl" src={require('../../assets/gamebase/jockeyClub/logo.jpg')} ></img>
                                    </div>
                                </div>
                            </div>
                            <p className="text-center mt-2 text-lg  ">{t('Jockey Club')}</p>
                            <p className="text-center mb-4 text-2xl font-bold text-gray-700">#{i}</p>
                            
                            <div className="hidden flex123 mb-4 px-4 ">
                                <button className=" w-full relative blingBtn p-4 specFont text-sm sm:text-xl text-white">{t('checkmore')}</button>
                            </div>
                           
                        </a>
                    </div> 
                )
            }
        </div>



        <div className="flex mb-10 hidden">
            <div className='shadow-md cursor-pointer bg-main bg-opacity-50 w-10 h-10  flex rounded-lg mr-3 '>
                <span className="m-auto text-main3"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.25 1.25L2 5L5.25 8.75" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.25 1.25L5 5L8.25 8.75" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
            </div>
            <div className='shadow-md cursor-pointer bg-main bg-opacity-50 w-10 h-10  flex rounded-lg mr-3 '>
                <span className="m-auto text-main3">1</span>
            </div>
            <div className='shadow-md cursor-pointer bg-main bg-opacity-50 w-10 h-10  flex rounded-lg mr-3 '>
                <span className="m-auto text-main3"><svg width="10" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.75 8.75L7 5L3.75 1.25" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M0.75 8.75L4 5L0.750001 1.25" stroke="currentColor" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg></span>
            </div>
        </div>

    </div>
}

export default MyWallet
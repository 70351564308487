import { menuData, brandLink } from "../global/constants"
import { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import Wallet from "./Wallet";


const Header = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const changeLanguage = () => {
        console.log(i18n.language)
        if (i18n.language == 'cn') {
            i18n.changeLanguage('en');
        }
        else {
            i18n.changeLanguage('cn');
        }

    };
    const [showSwapLink, setShowSwapLink] = useState(false)

    const [currPath, setCurrPath] = useState()
    const [showStoryMenu, setShowStoryMenu] = useState(false)

    let location = useLocation()

    function saveScrollId(scrollId) {
        console.log('sss')
        localStorage.setItem('scrollId', scrollId)

        scroller.scrollTo(scrollId, { smooth: true, offset: -150 })

        console.log(localStorage.getItem('scrollId'))
    }


    useEffect(() => {
        //asd()
    }, [])

    return <div className="relative">

        {showStoryMenu == true ?
            <div className="flex xl:hidden fixed w-[75vw] h-screen left-0 top-0   z-50 menubg">
                <div className="relative w-full mx-auto  py-6 bg-white  bg-opacity-75 px-10">
                    <div className="relative w-full h-20">
                     <div className="flex items-center justify-between">
                     <Link to={'/home'} className="relative  w-full mx-auto  " >
                    <img className=" object-contain h-10 " src={i18n.language == "cn" ? require('../../src/assets/logo.png') : require('../../src/assets/logo_eng.png') } alt="" />
                </Link>

                        <img className="w-6 h-6 object-contain  mx-auto cursor-pointer" src={require('../../src/assets/cancel.png')} alt="" onClick={() => setShowStoryMenu(false)} />

                         </div>
                    
                    </div>
                    {
                        menuData.map((i, index) =>
                            <Link key={index} to={i.path} className={location.pathname == i.path ? "rounded-lg w-full flex justify-start items-center py-4 cursor-pointer text-white px-5 bg-main mb-2" : "mb-2 rounded-lg w-full flex justify-start items-center py-4 cursor-pointer text-main3  px-5 "} onClick={() => setShowStoryMenu(false)}>

                                {i.icon}
                                <p className="ml-2 text-base font-bold">{i.title}</p>
                            </Link>

                        )
                    }
                    <div className="mb-2 rounded-lg w-full   py-4 cursor-pointer text-main3  px-5 " >
                    <div className="mb-2 flex w-full justify-start items-center" onClick={()=> setShowSwapLink(i=>!i)}>
                       <img className="w-4 h-4 relative"  style={{"filter":"grayscale(100%)"}} src={require('../assets/swap.png')} />
                       <p className="ml-2 text-base font-bold">Swap</p>
                       <img className="w-2 h-2 object-contain  ml-2" src={require('../assets/arrowdown.png')} alt="" />
                    </div>

                    {
                        showSwapLink?
                     <div className="pl-1">
                    {/* <a href="https://fstswap.finance/#/swap" target="_blank" className="mb-1 rounded-lg w-full flex justify-start items-center  cursor-pointer text-main3  px-5 ">
                       <img className="w-4 h-4" src={require('../assets/fst.png')} />
                       <p className="ml-2 text-base font-bold">FstSwap</p>
                    </a> */}
                    <a href="https://pancakeswap.finance/" target="_blank" className=" rounded-lg w-full flex justify-start items-center  cursor-pointer text-main3  px-5 ">
                       <img className="w-4 h-4" src={require('../assets/pancake.png')} />
                       <p className="ml-2 text-base font-bold">Pancakeswap</p>
                    </a>
                    </div>:""
                  }
                    </div>
                    
                    



                        <button className="flex border-solid border border-[#626969]  px-4 rounded-full  w-40  items-center justify-evenly mb-2" onClick={e => changeLanguage()}>
                        <img className="w-6 h-6 object-contain bg-[#626969] p-1 rounded-full" src={require('../assets/global.png')} alt="" />
                        <div className=" relative py-3 w-full font-bold text-[#626969]   " >{i18n.language == "cn" ? "简中" : "EN"}</div>
                        <img className="w-3 h-3 object-contain  " src={require('../assets/arrowdown.png')} alt="" />
                    </button>
                </div>

            </div>
            : ""}





        <div className="hidden lg:block fixed left-8 top-0 w-[237px] pb-5   z-20" style={{ 'box-shadow1': '2px 0px 10px 1px rgba(0, 0, 0, 0.2)' ,"height":"calc(100vh - 150px)"}}>
        <Link to={'/home'} className="relative py-4 w-full mx-auto  " style={{ 'box-shadow1': '0px 10px 15px 0px rgba(225, 69, 33, 0.28)' }}>
                    <img className=" object-contain w-full h-14 mx-auto mb-8 mt-10" src={i18n.language == "cn" ? require('../../src/assets/logo.png') : require('../../src/assets/logo_eng.png') } alt="" />
                </Link>


                
            <div className="relative h-full flex flex-col justify-between bg-white  bg-opacity-75 rounded-lg py-10 px-6">
                
               <div className="w-full mx-auto mb-4">
               <Wallet />
               </div>


                <div className="h-full overflow-auto  ">


                    {
                        menuData.map((i, index) =>
                            <Link key={index} to={i.path} className={location.pathname == i.path ? "rounded-lg w-full flex justify-start items-center py-4 cursor-pointer text-white px-5 bg-main mb-2" : "mb-2 rounded-lg w-full flex justify-start items-center py-4 cursor-pointer text-main3  px-5 "} >

                                {i.icon}
                                <p className="ml-2 text-base font-bold">{i.title}</p>
                            </Link>

                        )



                    }
                    
                    <div className="mb-2 rounded-lg w-full py-4  text-main3 px-5 " >
                        <div className="mb-2 w-full flex justify-start items-center cursor-pointer" onClick={()=> setShowSwapLink(i=>!i)}>
                       <img className="w-4 h-4 relative"  style={{"filter":"grayscale(100%)"}} src={require('../assets/swap.png')} />
                       <p className="ml-2 text-base font-bold">Swap</p>
                       <img className="w-2 h-2 object-contain  ml-2" src={require('../assets/arrowdown.png')} alt="" />
                       </div>

                       {
                        showSwapLink?
                        <div className="pl-1">
                    {/* <a href="https://fstswap.finance/#/swap" target="_blank" className="mb-1 rounded-lg w-full flex justify-start items-center cursor-pointer text-main3 px-5 ">
                       <img className="w-4 h-4 relative"  style={{"filter":"grayscale(100%)"}} src={require('../assets/fst.png')} />
                       <p className="ml-2 text-base font-bold">FstSwap</p>
                    </a> */}
                    <a href="https://pancakeswap.finance/" target="_blank" className=" rounded-lg w-full flex justify-start items-center cursor-pointer text-main3 px-5 ">
                       <img className="w-4 h-4 relative"  style={{"filter":"grayscale(100%)"}} src={require('../assets/pancake.png')} />
                       <p className="ml-2 text-base font-bold">Pancakeswap</p>
                    </a>
                    </div>
                    :""
                    }

                    </div>
                    
                    
                 



                </div>
                <div >
                    <button className="flex border-solid border border-[#626969]  px-4 rounded-full  w-full  mx-auto items-center justify-evenly " onClick={e => changeLanguage()}>
                        <img className="w-6 h-6 object-contain bg-[#626969] p-1 rounded-full" src={require('../assets/global.png')} alt="" />
                        <div className=" relative py-3 w-full font-bold text-[#626969]   " >{i18n.language == "cn" ? "简中" : "EN"}</div>
                        <img className="w-3 h-3 object-contain  " src={require('../assets/arrowdown.png')} alt="" />
                    </button>

                  


                </div>
                

            </div>

        </div>

        <div className=" relative z-10 ">
            <div className="min-h-[88px] mb-10">
                <div className="hidden lg:flex pl-[320px]  justify-between  pt-6 items-center ">
                    {
                        !(location.pathname == "/" || location.pathname == "/home")?
                        <div >
                        <span className="text-2xl  font-bold uppercase">{location.pathname == "/" ? "home":location.pathname.substring(1)}</span>
                        <div className="w-full bg-main3 h-1"></div>
                    </div>
                    :""
                    }
                    

                   

                </div>


            </div>



            <div className="lg:hidden fixed left-1/2 transform -translate-x-1/2 top-0 flex appWidth h-[88px] items-center justify-between " >


                <div className="bg-white bg-opacity-100 w-screen absolute h-full left-1/2 -translate-x-1/2 top-0 blur-[0px] shadow-lg"></div>
               
               <div className="flex items-center">
                <div className="relative" onClick={() => setShowStoryMenu(!showStoryMenu)}  >
                        <img className="flex xl:hidden w-7 h-7 cursor-pointer mr-4 object-contain"   src={require('../../src/assets/menu.png')} alt="" />

                    </div>

                <Link onClick={() => saveScrollId('home')} to={'/Home'} className="flex-none relative cursor-pointer ">
                    <img className="h-8 object-contain" src={i18n.language == "cn" ? require('../../src/assets/logo.png') : require('../../src/assets/logo_eng.png') } alt="" />
                </Link>

                </div>



                <div className="flex items-center z-50">
                
                    {/* <button className=" truncate hidden sm:block relative py-3 px-8 font-bold text-white border-2 border-main border-solid rounded-xl mx-[15px]" onClick={e => changeLanguage()}>{i18n.language == "zh-TW" ? "EN" : "繁中"}</button> */}
                    
                    <Wallet   />
                    

                </div>

            </div>


        </div>

    </div>

}
export default Header
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';
import Header from './compontents/Header'
import Footer from './compontents/Footer'


import Home from './pages/Home'
import Gamebase from "./pages/Gamebase";
import Gamedetail from "./pages/Gamedetail";
import INO from "./pages/INO";
// import INOdetail from "./pages/INOdetail";
import MyWallet from "./pages/MyWallet";

import "./i18n"

import { useTranslation } from "react-i18next";
import { t } from "react-i18next";



import { BrowserRouter ,Routes , Route } from 'react-router-dom';
import ScrollToTop from './compontents/ScrollToTop';
import './firebase.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter >
   <div className='relative w-full h-full bg-main2 text-main3'>
   <ScrollToTop/>
  <Header />
  <div className="flex">
  <div className='lg:w-[260px] flex-none'></div>
  <div className='w-full max-w-[1280px] appbg '>
    <div className='w-full relative'>
  <Routes>
  <Route path="*" element={<Home/>}></Route>
  <Route path="/home" element={<Home/>}></Route>
  <Route path="/gamebase" element={<Gamebase/>}></Route>
  <Route path="/gamedetail" element={<Gamedetail/>}></Route>
  <Route path="/ino" element={<INO/>}></Route>
  {/* <Route path="/inodetail" element={<INOdetail/>}></Route> */}
  <Route path="/mywallet" element={<MyWallet/>}></Route>

  </Routes>
  </div>
  <Footer />
  </div>
  </div>
  
  </div>

  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

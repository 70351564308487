
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { jockeyClub ,pamperDog,heroOfMech} from "../../global/constants";

const Gamedetail = () => {
    const { t } = useTranslation();
    const gamePic = [
        {
            img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F1.png?alt=media&token=754b82bc-f64a-448b-98e0-09b581207575",
        },
        {
            img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F2.png?alt=media&token=f7c54f32-8b20-4a30-b992-9d65351a15fe",
        },
        {
            img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F3.png?alt=media&token=28dff649-79cf-445b-a815-30a50c96e5e0",
        },
        {
            img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F4.png?alt=media&token=7b9cf208-9c68-4593-94be-5813dbd85a26",
        },
        {
            img: "https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/gamebase%2FjockeyClub%2F5.png?alt=media&token=892f1961-aac1-4d9e-90d4-c3bb68837f42",
        },
    ]
    const [selGame , setSelGame ] = useState(undefined)  

    

    const [currGamePic, setCurrGamePic] = useState(0)
    const countRef = useRef()

    function bannerloop() {
        if (countRef.current < selGame.banner.length - 1) {
            countRef.current = countRef.current + 1
            setCurrGamePic(countRef.current)
        }
        else {
            countRef.current = 0
            setCurrGamePic(0)
        }
        setTimeout(() => bannerloop(), 7000);
    }

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let game = urlParams.get('name')
        console.log(game)
        if (game == 'jockeyclub'){
            setSelGame(jockeyClub)
        }
        else if (game == 'pamperdog'){
            setSelGame(pamperDog)
        }
        else if (game == 'heroofmech'){
            setSelGame(heroOfMech)
        }


        countRef.current = 0
        setTimeout(() => bannerloop(), 7000);
    }, [])


    return <div className="appWidth ">



        {selGame != undefined ?
            <div className="relative mb-40 bg-main2 bg-opacity-90 rounded-md p-4">
        <img className=" absolute right-0 top-0 w-[25%] object-contain " src={require("../../assets/gamecorner.png")} />

            <div className="flex lg:mr-5 w-full  rounded-md  justify-center py-2 mb-4 lg:mb-0">
                <div className="w-60 float-none  mx-auto">
                    <div className='ratio-1/1 '>
                        <div className='ratio-in '>
                            <img className="w-full h-full object-cover rounded-xl" src={selGame.img} ></img>
                        </div>
                    </div>
                </div>

                <div className="px-4 w-full">
                <div className="lg:flex items-start justify-between mb-2">
                    <p className=" text-white  text-2xl font-bold mb-4">{t(selGame.name)}</p>

                    {
                        selGame.price != ""?
                    <div className=" mb-8">
                    <div className="flex  ">
                    <img className='w-3 h-3 mt-1 object-contain mr-1' src={require('../../assets/bannerTag1.png')}/>
                    <div>
                    <p className="text-white text-xs  uppercase">{t('ino.price')}</p>
                    <p className="text-white font-bold text-lg">{selGame.price} {selGame.unit}</p>
                    </div>
                    </div>
                </div>:""
                }

                    
                       { selGame.link != ""?
                        <a href={selGame.link} target="_blank" >
                            <span className="text-center  relative bg-main rounded-lg px-4 py-2 specFont text-sm text-white">{t('checkmore')}
                        </span>
                        </a>:""
                       }
                   
                </div>
                <p className="lg:block hidden text-base text-white">{t(selGame.desc)}</p>
                </div>

            </div>

            <p className="lg:hidden block  text-base text-white mb-4">{t(selGame.desc)}</p>


            <div className="h-[1px] w-full bg-white bg-opacity-80 my-2 "></div>


            <div className="w-full">
                <div className='ratio-16/9  mb-4'>
                    <div className='ratio-in bg-gray-800 rounded-lg overflow-hidden'>
                        <img className=" object-contain w-full h-full" src={selGame.banner[currGamePic].img} />
                    </div>
                </div>
                <div className="grid grid-cols-5 gap-2">
                    {
                        selGame.banner.map((i, index) =>
                            <div key={index} className='ratio-16/9  rounded-md'>
                                <div className='ratio-in '>
                                    <img className={currGamePic == index ? "rounded-md object-cover w-full h-full cursor-pointer brightness-100 anim" : "rounded-md object-cover w-full h-full cursor-pointer brightness-50 anim"} src={i.img} onClick={() => setCurrGamePic(index)} />
                                </div>
                            </div>
                        )
                    }


                </div>

            </div>

        </div>
        :""
}


        <img className="mx-auto absolute right-[0%] bottom-[-60px]  lg:-bottom-40 w-20 h-20 lg:w-40 lg:h-40 object-contain " src={require("../../assets/3.png")} />

    </div>
}

export default Gamedetail
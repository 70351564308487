import { brandLink } from "../../global/constants"
import CountDownTimer from "../../compontents/CountDownTimer";

import { useNavigate } from "react-router-dom";
import { useEffect } from "react"

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
const INO = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const startTime = Math.floor(1656245700 - (Date.now() / 1000) )


    function navINODetail (){
        //if ( startTime <= 0){
          navigate('/inodetail')
        //}
        
    }

    useEffect(() => {
        const scrollToId = localStorage.getItem('scrollId')
        console.log(scrollToId)
        if (scrollToId != null) {
            scroller.scrollTo(scrollToId, { smooth: true, offset: -150 })
        }
    })

    return <div className="appWidth min-h-screen">


        {/* <div onClick={()=>navINODetail()} className=" gap-3 grid grid-cols-9 justify-between px-4 items-center  bg-main2  rounded-md cursor-pointer mb-[500px] shadow-lg ">
            
            <div className="col-span-3 lg:flex flex-none mb-2 lg:mb-0 py-4">
                <img className="rounded-md w-40 h-28 lg:w-32 lg:h-20 object-cover object-bottom mr-3 lg:mb-0 mb-2" src={"https://firebasestorage.googleapis.com/v0/b/fonfun-e631c.appspot.com/o/banner%2F4.jpg?alt=media&token=3804052c-4e34-4a42-8eca-93bf9d0e35e6"} />
                <span className=" self-center text-3xl font-bold text-white">{t('Jockey Club')}</span>
            </div>

            <img className=' col-span-1 w-10 h-full object-cover  ' src={require('../../assets/tagBar.png')}/>
            <div className="col-span-5 w-full lg:grid grid-cols-2 items-center justify-start ">


                <div className="mb-4 lg:mb-0">
                    <div className="flex ">
                    <img className='w-3 h-3 mt-1 object-contain mr-1 ' src={require('../../assets/bannerTag1.png')}/>
                    <div>
                    <p className="text-white text-sm font-bold uppercase">{t('ino.total sales')}</p>
                    <p className="text-white font-bold text-2xl">15,000</p>
                    </div>
                    </div>
                </div>

                <div className="">
                    <div className="flex  ">
                    <img className='w-3 h-3 mt-1 object-contain mr-1' src={require('../../assets/bannerTag1.png')}/>
                    <div>
                    <p className="text-white text-sm font-bold uppercase">{t('ino.price')}</p>
                    <p className="text-white font-bold text-2xl">200 FIST/BOX</p>
                    </div>
                    </div>
                </div>

                
                
                    {
                        startTime > 0 ?
                        <div>
                        <p className="text-main4 text-sm font-bold uppercase">{t('Start Time')}</p>
                            <CountDownTimer closeTime={startTime} />
                            </div>
                            : ""
                    }
            

            </div>


        </div> */}


        {/* <div className="flex justify-center ">
        {
                [1,2,3].map((i,index) =>
                    <div key={i} className='flex-none mx-1 mt-1'>
                        {
                            index == 0?
                          <img className='w-2 h-2 object-contain cursor-pointer ' src={require('../../assets/bannerTag2.png')}/>
                          :
                          <img className='w-2 h-2 object-contain cursor-pointer ' src={require('../../assets/bannerTag1.png')} />
                        }
                        
                    </div>
                )}
        </div> */}

        <img className="mx-auto absolute right-[0%] bottom-0  lg:-bottom-0 w-20 h-20 lg:w-40 lg:h-40 object-contain " src={require("../../assets/2.png")} />

    </div>
}

export default INO
//import Web3 from "web3";

import { useState,useEffect } from "react";

import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnect from "@walletconnect/web3-provider";
import { useTranslation } from "react-i18next";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useNavigate
} from "react-router-dom";

const providerOptions = {
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: 'process.env.INFURA_KEY' // required
    }
  }
};

const web3Modal = new Web3Modal({
  cacheProvider: true, // optional
  providerOptions // required
});



export default function Wallet() {
  const { t } = useTranslation();

  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();
  

  const [loading, setLoading] = useState(false);

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setChainId(network.chainId);
    } catch (error) {
      setError(error);
    }
  };
  
  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };
  const navigate = useNavigate();

  function btnfunction(){
    if (!account){
      connectWallet()
    }
    else{
      navigate('/mywallet')
    }
  }
  
  
  
  useEffect(()=>{
    if (window.ethereum){
      window.ethereum.on("accountsChanged", (accounts) => {
        //console.log('accountsChanged')
        connectWallet()
      })
      // window.ethereum.on("disconnect", (accounts) => {
      //   //console.log('disconnect')
      //   disconnect()
      // })

      connectWallet()
  
    }
    
    
  },[])
  return <div className="w-full border-solid border-main2 border rounded-full overflow-hidden ">
    <button className=" relative  overflow-hidden specFont text-base text-main2 font-bold" onClick={()=>btnfunction()}>{!account ? (
    <div className="px-4 py-2 flex " >
       <img className=" mr-2 w-6 h-6 object-contain bg-main2 p-1 rounded-full" src={require('../assets/Wallet.png')} alt="" />
      {t('connect')}
      </div>
  ) : (

    <div className="h-12 text-sm flex items-between pl-1 w-full items-center" to="/mywallet">
      <p className="px-2">{t('My NFT')} {account.substring(0,4)+"..."+account.substring(account.length-4,account.length)}</p>
    <div className="bg-gray-800 flex-none p-1  w-8 sm:w-10 h-full ">
    <img className="w-full h-full object-contain" src={require('../assets/logout.png')} onClick={()=>disconnect()} />
    </div>
    

    
    </div>
  )}
  </button>
  </div>
  
}


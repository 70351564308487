

import { useTranslation } from "react-i18next";
import ReactDOM from 'react-dom/client';

import { Link as LinkScroll, animateScroll as scroll, scroller } from "react-scroll";

import React, { useState, useEffect ,useRef} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


const Home = () => {
    const { t,i18n } = useTranslation();
    const banner = [
        {
            title:'banner2',
            img: i18n.language == "cn" ? require('../../assets/banner/2.jpg'):require('../../assets/banner/2_eng.jpg'),
            content: 'Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro Intro'
        },
    ]

    const [currBanner, setCurrBanner] = useState(0)
    const countRef = useRef()


    const platformInfo = [
        {
            title:'Twitter Followers',
            value:'1,000+'
        },
        {
            title:'Telegram Members',
            value:'7,000+'
        },
    ]

    const [faqs, setFAQ] = useState([
        

    ]);



    const [FAQType, setFAQType] = useState(0);
    const [isFisrt, doneFisrt] = useState(false)

    function changeFAQType(type) {
        setFAQType(type)
    }

    function changeFAQItem(index) {
        console.log(faqs[FAQType][index])
        faqs[FAQType][index].open = !faqs[FAQType][index].open
        console.log(faqs[FAQType][index])
        setFAQ([...faqs])
    }

  
    function bannerloop() {
        if (countRef.current < banner.length - 1){
            countRef.current = countRef.current + 1
            setCurrBanner(countRef.current)
        }
        else{
            countRef.current = 0
            setCurrBanner(0)
        }
        setTimeout(() => bannerloop(), 7000);
    }




    useEffect(() => {
        countRef.current = 0
        const scrollToId = localStorage.getItem('scrollId')
        console.log(scrollToId)
        console.log(isFisrt)
        if (isFisrt == false) {
            setTimeout(() => bannerloop(), 7000);
            doneFisrt(true)
            console.log(isFisrt)
            if (scrollToId != null) {
                scroller.scrollTo(scrollToId, { smooth: true, offset: -150 })
            }

        }

    },[]);


    return <div className="appWidth " >
        <div className='relative  mb-10 '>
            <div className='w-full'>
            <div className='ratio-16/9'> 
                <div className='ratio-in bg-[#828282] bg-opacity-70 rounded-[30px] p-4 '>
                <img className="rounded-[24px] w-full h-full  object-cover  " src={banner[currBanner].img}></img>

                <div className="flex justify-center ">
                {
                banner.map((i,index) =>
                    <div key={i} className='flex-none mx-1 mt-1'>
                        {
                            index == currBanner?
                          <img className='w-2 h-2 object-contain cursor-pointer ' src={require('../../assets/bannerTag2.png')}/>
                          :
                          <img className='w-2 h-2 object-contain cursor-pointer ' src={require('../../assets/bannerTag1.png')} onClick={()=>setCurrBanner(index)}/>
                        }
                        
                    </div>
                )}

                </div>

                </div>

            </div>
            </div>
            <div className='w-full sm:w-3/4 py-10 '>
                <p className='text-main3 text-3xl font-base mb-4'>
                <span className="text-main">{t('name')}</span>{t('home.title')}
                </p>
                <p className='text-main3 text-lg '>
                {t('home.content')}
                </p>

            </div>


        </div>
        

        <div className='mb-14 relative'>
   
            <p className='text-main3 text-4xl font-bold mb-10'>FONFUN</p>
            <div className='w-full lg:w-[80%] flex justify-center'>
            {
                platformInfo.map((i,index) =>
                
                            <div key={i}  className={`social${index+1}  py-9  w-48 h-48`}>
                                <img className="mx-auto relative w-8 h-8 object-contain mb-4" src={index== 0 ?require("../../assets/brand/twitter.png"):require("../../assets/brand/tg.png")} />
                                <p className="text-xs text-white mb-1 relative text-center" >{i.title}</p>
                                <p className="text-white text-2xl font-black relative text-center">{i.value}</p>
                                
               
                        </div>

                )}
                </div>


        <img className="mx-auto absolute right-[0%] bottom-[-60px]  lg:-bottom-8 w-20 h-20 lg:w-40 lg:h-40 object-contain " src={require("../../assets/1.png")} />
        
        </div>

    </div>
}

export default Home